import axios from "axios";
import { store } from "../store";
import { logout } from "../store/slices/userSlice";
import { toast } from "react-toastify";

export const API_URL = "https://info.stocometry.com/api";
// export const API_URL = "http://localhost:80/api";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(async (req) => {
  const accessToken = store.getState().user?.accessToken;
  req.headers.Authorization = `Bearer ${accessToken}`;
  return req;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log({ error });
    if (error?.response?.status === 401) {
      store.dispatch(logout());
      toast.error("Session Expired", {
        autoClose: 2000,
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
