import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className='section'>
      <div id="footer" className='pt-2 pl-5 pr-5' style={{backgroundColor:"#1d2636"}}>
        {/* <div className="utf-footer-section-item-block pt-3 ">
        <div className="container">
          <div className="row px-2">
            <div className="col-xl-4 col-md-12">
              <div className="utf-footer-item-links">
                        
                      
                <p className="pt-3">
                <strong>Info.stocometry.com</strong> is a search platform designed to provide specific results based on your queries. It offers advanced search capabilities and filters to help users quickly find relevant information.
                </p>
              </div>
            </div>
            <div className="col-xl-2 col-md-3 col-sm-6">
            <div>
              <div className="utf-footer-item-links">
                <h3 className="">Quick Links</h3></div>
                <ul className=" list-unstyled pt-3">
                  <li>
                    <Link
                      to="/aboutus"
                      className="text-dark text-decoration-none"
                    >
                      <i className="bx bx-chevron-right"></i>{" "}
                      <span>About Us</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Contactus"
                      className="text-dark text-decoration-none"
                    >
                      <i className="bx bx-chevron-right"></i>{" "}
                      <span>Contact Us</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/PrivacyPolicy"
                      className="text-dark text-decoration-none"
                    >
                      <i className="bx bx-chevron-right"></i>{" "}
                      <span>Privacy Policy</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/TermsCondition"
                      className="text-dark text-decoration-none"
                    >
                      <i className="bx bx-chevron-right"></i>{" "}
                      <span>Term & Condition</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        {/* Footer Copyrights */}

        <div className=' footer-container  flex-wrap pr-5 pt-3 pb-3 pl-5 '>
          <div>
            <a
              href="/"
              className="text-decoration-none"
            >
              <img src="/assets/img/logo.png" alt="img" className="img-fluid logoimage" style={{filter: "brightness(0) invert(1)"}}></img>
            </a>
          </div>
          <div className='d-flex align-items-center justify-content-center '>
            <div>
              <Link
                to="/aboutus"
                className=" pr-3 footer-links text-decoration-none"
              >
                <span>About Us</span>
              </Link>
            </div>
            <i className='bx bxs-circle' style={{fontSize:10, color:"#ffffffbf"}}></i>
            <div>
              <Link
                to="/Contactus"
                className=" pl-3 pr-3 footer-links text-decoration-none"
              >
                <span>Contact Us</span>
              </Link>
            </div>
            <i className='bx bxs-circle' style={{fontSize:10, color:"#ffffffbf"}}></i>
            <div>
              <Link
                to="/PrivacyPolicy"
                className=" pl-3 pr-3 footer-links text-decoration-none"
              >
                <span>Privacy Policy</span>
              </Link>
            </div>
            <i className='bx bxs-circle' style={{fontSize:10, color:"#ffffffbf"}}></i>
            <div>
              <Link
                to="/TermsCondition"
                className=" pl-3 pr-3 footer-links text-decoration-none"
              >
                <span>Term & Condition</span>
              </Link>
            </div>
          </div>
        </div>
        <div className=' mr-5 mb-5 ml-5' style={{height:3, backgroundColor:"#ffffffbf"}}>

        </div>

        <div className="utf-footer-copyright-item " style={{color:"#ffffffbf"}}>
          <div className="container">
            <div className="row text-lg-center">
              <div className="col-xl-12 pb-3 ">
                <span className='font-weight-bold'>&copy;</span> info.stocometry.com <br /> 2024 All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
        {/* Footer Copyrights / End */}
      </div>
    </div>
  );
};
export default Footer;
