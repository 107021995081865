import React, { useEffect } from 'react';

const Ads = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.innerHTML = `
            try {
                window._mNHandle.queue.push(function () {
                    window._mNDetails.loadTag("380770422", "300x250", "380770422"); 
                });
            } catch (error) {
                console.error(error);
            }
        `;


        
        document.getElementById('380770422').appendChild(script);

        return () => {
            document.getElementById('380770422').removeChild(script);
        };
    }, []);

    return (
        <div>
            testing add
            <div id="380770422"></div>
        </div>
    );
};

export default Ads;