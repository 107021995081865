import React from 'react';
import { Link } from 'react-router-dom';

const Counters = () => {
  const cardData = [
    {
      title: "Health and Wellness",
      iconClass: "bx bx-group",
      link: "/SearchResult?q=Health&Wellness",
      description: "10.2k+ articles",
    },
    {
      title: "Business",
      iconClass: "bx bx-briefcase-alt",
      link: "/SearchResult?q=Business",
      description: "1700+ articles",
    },
    {
      title: "Technology",
      iconClass: "bx bxs-city",
      link: "/SearchResult?q=Technology",
      description: "5.9k+ articles",
    },

    {
      title: "Shopping & Ecommerce",
      iconClass: "bx bx-message-dots",
      link: "/SearchResult?q=Shopping and Ecommerce",
      description: "3.4k+ articles",
    },

  ];

  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="utf-section-headline-item text-center">
              <span>We Are Growing With</span>
              <h3>
                Speed Relevance Precision Privacy
              </h3>
              <div className="utf-headline-display-inner-item">
              </div>
              <p className="utf-slogan-text">
                We revolutionize your online search experience by providing fast, accurate, and comprehensive results tailored to your needs. Info.stocometry is your go-to search engine for all your online queries.
              </p>
            </div>
          </div>
        </div>
        <div className='row  m-0'>
          {cardData.map((card, index) => (
            <div key={index} className='col-lg-3 col-md-6 col-12 mt-lg-0 mt-2 '>
              <div className="card-body text-center cards w-100  h-100">
                <a href={card.link} className='text-decoration-none text-dark'>
                  <h5 className="card-title">
                    <i className={`bx ${card.iconClass}`}></i>
                  </h5>
                  <h2 className="card-text">{card.title}</h2>
                  <p className="card-text">{card.description}</p>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Counters;
