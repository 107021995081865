import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { getData } from "../../api/Requests/authentication";
import { useSelector } from "react-redux";
import CustomLoader from "../../common/components/CustomLoader";
// import "./siteData.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "../../utility";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { areDates15DaysApart } from "./utility";
import Header from "../Header";
import TimeClock from "../TimeClock";

const columns = [
    {
        name: "Ad Units",
        style: { border: "1px solid #ccc" },
        selector: (row) => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{row.adtag}</div>,
    },
    {
        name: "Channel Name",
        selector: (row) => row.channelName,
        style: { border: "1px solid #ccc" }
    },
    {
        name: "Channel Name 2",
        selector: (row) => row.channelName2,
        style: { border: "1px solid #ccc" }
    },
    {
        name: "Channel Name 3",
        selector: (row) => row.channelName3,
        width: "350px",
        style: { border: "1px solid #ccc" }
    },
    // {
    //     name: "Date",
    //     selector: (row) => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{row.date}</div>,
    // },
    {
        name: "Estimated Revenue ($)",
        selector: (row) => parseFloat(row.estimated_revenue).toFixed(2),
        sortable: true,
        style: { border: "1px solid #ccc" },
        width:"200px",
        center:true
    },
    {
        name: "Impressions",
        selector: (row) => Number(row.impressions),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true
    },
    {
        name: "Searches",
        selector: (row) => Number(row.searches),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true
    },
    {
        name: "Total Clicks",
        selector: (row) => Number(row.total_clicks),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true
    },
];

const CombinedCampaignData = (props) => {
    const [data, setData] = useState({});
    const { apiToken, accessToken } = useSelector((state) => state.user);
    const history = useHistory();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(500);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [timeZone,setTimeZone] = useState("UTC")
    const [dateRange, setDateRange] = useState(7);
    const [combinedData, setCombinedData] = useState({
        Revenue:0,
        Impressions:0,
        Searches:0,
        TotalClicks:0
    })


    const handlePerRowsChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handleStartDate = (date) => {
        setStartDate(date);
    };

    const getTableData = async () => {
        try {
            setIsLoading(true);
            if (endDate < startDate) {
                toast.error("EndDate cannot be before Start Date", {
                    autoClose: 2000,
                });
                setData({});
            } else if (areDates15DaysApart(startDate, endDate)) {
                toast.error("EndDate and Start Date cannot have more than 15 days gap", {
                    autoClose: 2000,
                });
                setData({});
            } else {
                const payload = {

                    start_date: getFormattedDate(startDate),
                    end_date: getFormattedDate(endDate),
                    page_size: perPage,
                    page_no: page,
                    apiToken,
                };
                const res = await getData(payload);
                if (res.status === 200) {
                    const formattedData = formatData(res.data.message.rows)
                    setData({ ...res.data.message, rows: formattedData });
                } else {
                    setData({});
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const formatData = (data) => {
        const uniqueIds = [...new Set(data.map(obj => obj.channelName2))];

        const groupedData = []
        uniqueIds.map(id => {
            let acc = {}
            let total_clicks = 0
            let searches = 0
            let impressions = 0
            let estimated_revenue = 0
            data.filter(obj => obj.channelName2 === id).map((obj) => {
                acc = obj
                total_clicks = obj.total_clicks + total_clicks;
                searches = obj.searches + searches;
                impressions = obj.impressions + impressions;
                estimated_revenue = obj.estimated_revenue + estimated_revenue;
            })
            groupedData.push({ ...acc, total_clicks, searches, impressions, estimated_revenue})
        });

        // console.log(groupedData)
        createTotal(groupedData)
        return groupedData

    }

    useEffect(() => {
        getTableData();
    }, [perPage, page]);

    const createTotal = (data) =>{
        let Revenue = 0
        let Impressions = 0
        let Searches = 0
        let TotalClicks = 0
    
        data.map((row)=>{
          Revenue = Revenue + Number(row.estimated_revenue)
          Impressions = Impressions + Number(row.impressions)
          Searches =  Searches + Number(row.searches)
          TotalClicks = TotalClicks + Number(row.total_clicks)
        })
    
        setCombinedData({
          Revenue: parseFloat(Revenue).toFixed(2),
          Impressions,
          Searches,
            TotalClicks
        })
        console.log({
          Revenue: parseFloat(Revenue).toFixed(2),
          Impressions,
          Searches,
            TotalClicks
        })
        return
    }




    return (
        <div className="page-container" >
            {/* Header */}
            <Header />
            <div className="page-title">
                <h1>Combined Campaign Data</h1>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignContent:"center", gap:5}}> 
                    <select defaultValue={timeZone} onChange={(e)=>setTimeZone(e.target.value)}>
                    <option value="UTC">UTC</option>
                    <option value="Asia/Kolkata">IND</option>
                    <option value="America/Los_Angeles">US</option>
                </select>
                <span style={{ fontSize:15}}><TimeClock timezone={timeZone}/></span>
                </div>
            </div>

            <div className="date-filter-container">
                <div className="date-picker-wrapper">
                    <label htmlFor="start-date">Start Date:</label>
                    <DatePicker
                        id="start-date"
                        selected={startDate}
                        onChange={handleStartDate}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select Start Date"
                        maxDate={new Date()}
                    />
                </div>

                <div className="date-picker-wrapper">
                    <label htmlFor="end-date">End Date:</label>
                    <DatePicker
                        id="end-date"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select End Date"
                        maxDate={new Date()}
                    />
                </div>
                <div onClick={() => { getTableData() }} className="date-picker-wrapper " style={{ backgroundColor: "green", color: "white", paddingLeft: 10, paddingRight: 10, cursor: "pointer", justifyContent: "center", alignItems: "center", marginTop: 15, marginBottom: 0, borderRadius: 10 }}>
                    Submit
                </div>
            </div>

            <div style={{display:"flex", flexWrap:"wrap",flexDirection:"row", gap:15, marginBottom:20, paddingLeft:20}}>
                <div className="border border-black py-3 px-5 rounded-lg">
                    <h3>Estimated Revenue ($)</h3>
                    <h4 className=" text-center">{combinedData.Revenue}</h4>
                </div>
                <div className="border border-black py-3 px-5 rounded-lg">
                    <h3>Impressions</h3>
                    <h4 className=" text-center">{combinedData.Impressions}</h4>
                </div>
                <div className="border border-black py-3 px-5 rounded-lg">
                    <h3>Searches</h3>
                    <h4 className=" text-center">{combinedData.Searches}</h4>
                </div>
                <div className="border border-black py-3 px-5 rounded-lg">
                    <h3>Total Clicks</h3>
                    <h4 className=" text-center">{combinedData.TotalClicks}</h4>
                </div>
            </div>

            {/* Table Container */}
            <div className="table-site-container">
                <DataTable
                    columns={columns}
                    data={data.rows}
                    showGridlines
                    pagination
                    paginationServer
                    progressPending={isLoading}
                    paginationTotalRows={data?.pagination?.total_rows}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[500, 600, 700]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<CustomLoader />}
                    fixedHeader
                    customStyles={{
                        headCells: {
                          style: {border: "1px solid #ccc"
                          },
                        },          
                      }}
                    fixedHeaderScrollHeight="calc(100vh - 350px)"
                />
            </div>
        </div>
    );
};

export default CombinedCampaignData;
