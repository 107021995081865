import axiosInstance from "../requestHandler";

export const addCampaign = async (data) => {
  try {
    const response = await axiosInstance.post(`/map/campaign`, data);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
export const getCampaignData = async () => {
  try {
    const response = await axiosInstance.get(`/all/campaign`);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
export const getCustomCampaignData = async (payload) => {
  try {
    const response = await axiosInstance.post(`/get/campaign-data`, payload);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
export const getAllCampaignId = async () => {
  try {
    const response = await axiosInstance.get(`/all/campaignId`);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
export const getAllSmid = async () => {
  try {
    const response = await axiosInstance.get(`/all/smid`);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
