import React, { useEffect, useState } from "react";
import "./addCampaign.css";
import { getCampaignData, addCampaign } from "../../api/Requests/campaign";
import CustomLoader from "../../common/components/CustomLoader";
import Header from "../Header";

const AddCampaign = () => {
  const [campaigns, setCampaigns] = useState([]);

  const [newCampaignId, setNewCampaignId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddCampaign = async () => {
    try {
      setLoading(true);
      const res = await addCampaign({ arr: [newCampaignId] });
      if (res.status === 200) {
        setNewCampaignId("");
        getList();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getList = async () => {
    try {
      setLoading(true);

      const res = await getCampaignData();
      if (res.status === 200) {
        console.log(res?.data?.message);
        setCampaigns(res?.data?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="app-container">
      {/* Header */}
      <Header />
      <header className="header">
        <h1>Campaign Manager</h1>
      </header>

      {/* Main Content */}
      <main className="main-content">
        {/* Add Campaign Section */}
        <section className="add-campaign">
          <h2>Add New Campaign</h2>
          <div className="input-group">
            <input
              type="text"
              value={newCampaignId}
              onChange={(e) => setNewCampaignId(e.target.value)}
              placeholder="Enter new Campaign ID"
              className="input"
            />
            {loading ? (
              <CustomLoader size="20px" />
            ) : (
              <button
                onClick={handleAddCampaign}
                disabled={newCampaignId === ""}
                className={` ${newCampaignId === "" ? "disable-button" : "add-button"}`}
              >
                Add Campaign
              </button>
            )}
          </div>
        </section>

        {/* Campaign List Section */}
        <section className="campaign-list">
          <h2>Campaign List</h2>
          <div className="table-container">
            <table className="campaign-table">
              <thead>
                <tr className="table-row">
                  <th className="table-head">ID</th>
                  <th className="table-head">Campaign ID</th>
                  <th className="table-head">URL</th>
                </tr>
              </thead>
              <tbody className="campaign-table-body">
                {loading ? (
                  <tr>
                    <td colSpan="4">
                      <div className="campaign-loader">
                        <CustomLoader />
                      </div>
                    </td>
                  </tr>
                ) : campaigns.length === 0 ? (
                  <tr className="table-row">
                    <td colSpan="4" className="table-data empty-message">
                      No campaigns found.
                    </td>
                  </tr>
                ) : (
                  campaigns.map((campaign) => (
                    <tr key={campaign.id} className="table-row">
                      <td className="table-data">{campaign.id}</td>
                      <td className="table-data">{campaign.campaign_id}</td>
                      <td className="table-data">{campaign.url}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>

      {/* Footer */}
    </div>
  );
};

export default AddCampaign;
