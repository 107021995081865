import React, { useState, useEffect, useN } from 'react';
import Header from './Header'
import Footer from './Footer'
import axios from 'axios'
import { blogsList } from './blogs';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { NavLink } from "react-router-dom"
import AdvertisementSection from './AdvertisementSection';

const Blog = () => {
    const [data, setData] = useState()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const blogData = blogsList.filter((obj) => obj.id == id)[0]
    const blogList = blogsList
    console.log(blogData)
    const init = async () => {
        try {
            const response = await axios.get("http://localhost:5000/api/jobin/getAllProduct")
            console.log(response.data.result)
            setData(response.data.result)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])
    return (
        <>
            <Header />
            <div className='container blog-flex-box h-100 w-100' style={{marginTop:25}}>
                <div className='blog-div'  >
                    {blogData && <div>
                        <h6 className='blog-title'>{blogData?.title}</h6>
                    {blogData?.image &&
                        <img style={{ marginTop: 10, height:500 }} className='w-100 mx-10' src={blogData?.image} alt="" />}
                    <p style={{ marginTop: 15, textAlign:"justify" }}>{blogData?.introduction}</p>
                    {blogData?.content?.length > 0 && <div>
                        {
                            blogData?.content?.map((heading, i) => (
                                <div>
                                    <h6 className='blog-heading'> {i + 1} : {heading?.heading}</h6>
                                    {heading?.headingContent?.map((para) => (
                                        <div>
                                            {para?.para && <p style={{textAlign:"justify"}}>{para?.para}</p>}
                                            {para?.bullets && para?.bullets.map((points,i)=>(
                                                <p style={{textAlign:"justify"}}>{i + 1}. {points.key}: {points.value}</p>
                                            ))}
                                        </div>
                                    ))}
                                    { i == 0 && <AdvertisementSection/> 
                                    }
                                </div>
                            ))
                        }
                    </div>}
                        </div>}
                </div>

                <div className='blog-list-div'>
                <div style={{ backgroundColor: "#f1efef", border: 1, borderColor: "#f1f1f1", marginLeft:25, borderRadius: 5, marginTop: 5, paddingTop:25, paddingBottom:25 }} >
                    {blogList.length > 0 && blogList.slice(-5).map((obj) => (
                        <NavLink to={`/blog?id=${obj.id}`} className='d-flex blog-link py-2 px-5 '>
                            <img className='w-50 mh-25 pr-2' style={{height:80}} src={obj.image || "https://archive.org/download/placeholder-image/placeholder-image.jpg"} alt="https://archive.org/download/placeholder-image/placeholder-image.jpg" />
                            <h6 style={{ cursor: 'pointer', overflowWrap: "break-word" }} className=' text-danger-hover py-2'> {obj.title?.split(":")[0]}</h6>
                        </NavLink>
                    ))}
                </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Blog