import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
const ResultsearchForm = () => {
  const [linkDirect,setLinkdirect]=useState()
  var base_url = window.location.origin;
  // "http://stackoverflow.com"
  // // console.log("base_url",base_url);
  // var host = window.location.host;
  //// console.log("host",host);
  const [location,setlocation] = useState();
  const[searchvalue,setsearchvalue] = useState();
  // console.log("location",location)
  const pageLocation = useLocation();
  const searchParams = new URLSearchParams(pageLocation.search);
  const jobval = searchParams.get('q');
  // let jobval = localStorage.getItem("job");
  let locationjob = localStorage.getItem("location");
  const [job,setjob] = useState(jobval);
  // // console.log("job",job)
  const handleKeypress = e => {
    //it triggers by pressing the enter key
  if (e.key === "Enter") {
    const jobss = job.replace(/\s/g, '+')
   let val = "";
    // console.log("jobss",jobss)
    localStorage.setItem("job",job)
    localStorage.setItem("location",location)
    if(location != undefined && job != undefined  ){
      val = jobss + "+" + location;
     setsearchvalue(val) 
    }
    else if(job == undefined ){
      alert("Enter the Job you want to search")
      setLinkdirect(true)
    }
    else{
         val = jobss
        setsearchvalue(val) 
    }
    if(jobss !== undefined){
      window.location.href = `/SearchResult?q=${jobss}#gsc.tab=0&gsc.q=${jobss}&gsc.page=1`;
      //location.replace(`/SearchResult?q=${jobss}#gsc.tab=0&gsc.q=${jobss}&gsc.page=1`)
    // history.push(`/SearchResult?q=${jobss}#gsc.tab=0&gsc.q=${jobss}&gsc.page=1`)
    document.getElementById("linkterget");
  }
  }
};
  const searchresult = () => {
    // console.log("job",job)
    // console.log("location",location)
    const jobss = job.replace(/\s/g, '+')
    // console.log("jobss",jobss)
    localStorage.setItem("job",job)
    localStorage.setItem("location",location)
    if(location != undefined && job != undefined  ){
     const val = jobss + location;
     setsearchvalue(val) 
    }
    else if(job == undefined ){
      alert("Enter the Job you want to search")
    }
    else{
        const val = jobss
        setsearchvalue(val) 
    }
     
  }
  // console.log("searchvalue",searchvalue);
  return (
    <div className="container desktop mt-3">
      <div
        className="d-lg-flex  justify-content-center w-100 searchFormSubmit job-search-form"
        
      >
        <div className="w-100 d-lg-flex align-items-center main-header-div main-search-head-div">
          <div className="d-md-flex container-wrapping-head w-100">
            <div className="d-flex w-100">
          
              <div className="w-100 job-input-div d-flex justify-content-center align-items-center">
                <input
                  type="text"
                  placeholder="Search keyword"
                  className="form-control seacrh-jobs-input w-100 border-0"
                  id="seacrh-jobs-input"
                  name="keyword"
                  onKeyPress={handleKeypress}
                 onChange={(e) => {
                  setjob(e.target.value);
                  }}
                  
                value={job}
                />
              </div>

              {/* Search job button */}
             
    
              {/* Job filter button */}
            </div>
            
          </div>
          <div 
          className="find-jobs-btn-div d-lg-block ms-3 text-nowrap ml-2 py-3 "
          onClick={searchresult}
          >
          <a href={`/SearchResult?q=${searchvalue}`}>
            <button className="border-0 find-job-btn " type='submit'>
              Search
            </button>
</a>
          </div>
        </div>
     </div>
    </div>
  );
};
export default ResultsearchForm;
