import React, { useEffect, useState } from "react";
import Header from './Header';
import Footer from './Footer';
import ResultsearchForm from "./ResultsearchForm";
import { useHistory, useLocation } from 'react-router-dom';
import axios from "axios";
import { blogsList } from "./blogs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AdvertisementSection from "./AdvertisementSection";
const SearchResult = () => {
  const regex =
    /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
  const [emailinputs, setEmail] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState("");
  const [emailinput, setEmailInput] = useState();
  const [subscribeds, setsubscribeds] = useState(false);
  const [emailcheck, setEmailCheck] = useState();
  const blogList = blogsList
  const [jobsArray, setJobsArray] = useState([])
  const [googleResultArray, setGoogleResultArray] = useState([])
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('q');
  const apiKey = "AIzaSyD7_AWhDcdcXpnvMrofqzRjkSvmpfbi1aw"
  // const newString = search.replace('?', '');
  const pathname = window.location.pathname;
  const history = useHistory();
  const url = window.location.href;
  let invalidemail = "Enter the valid Email";
  let valid = "";
  let emailValues = "";
  const checkEmail = (e) => {
    emailValues = e.target.value
    setEmail(e.target.value);
    setEmailInput(e.target.value)
    if (regex.test(e.target.value) === false) {
      setError(invalidemail);
      setEmailCheck(false)
    }
    else {
      setError(valid);
      setEmailCheck(true)
    }
  };
  document.addEventListener(
    "click",
    function (event) {
      // If user either clicks X button OR clicks outside the modal window, then close modal by calling closeModal()
      if (
        event.target.matches(".adswindow")

      ) {
        setModalShow(false)
      }
    },
    false
  )
  const model = () => {
    setModalShow(false)
  }
  const showmodel = () => {
    setModalShow(true)
  }

  window.addEventListener("load", (event) => {
    setTimeout(showmodel, 4000);
  })
  const subscribe = async (e) => {
    e.preventDefault();
    if (emailcheck === true) {
      try {
        const data = await axios.get(`/api/memberAdd?email=${emailinput}`)
        setsubscribeds(true)
      }
      catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    const filteredList = blogList.filter((obj) =>
      // Combine property iteration with case-insensitive search
      JSON.stringify(obj).toLowerCase().includes(id.toLowerCase())
    );
    console.log(filteredList)
    setJobsArray(filteredList)
  }, [id])


  //  window.googletag = window.googletag || {cmd: []};
  //  googletag.cmd.push(function() {
  //    googletag.defineSlot(`/22854896206/info.stocometry_sidebar_ad1`, [[240, 400], [300, 250]], 'div-gpt-ad-1670930615804-0').addService(googletag.pubads());
  //    googletag.pubads().enableSingleRequest();
  //    googletag.enableServices();
  //  });

  return (
    <>
      {/* <div className={modalShow ? 'w-100 adswindow' :"d-none"}> 
<div className={modalShow ? 'adsdata mx-auto position-fixed ' :"d-none"}>
<div className="p-2 bg-white popupwindowsize">
    <div className="text-right crossbtn mr-3" onClick={model}>
      <i className="bx bx-x h3"></i>
    </div>
    <div className="text-center">
      <h3 className="popupwindow">Few Quick Results For you</h3>
    </div>
    <div className="gcse-searchresults-only"></div>
</div>
</div>
</div> */}
      <div className="">

        <Header />
        <div className="backgroundbody">
          <div className=' col-12 d-none d-lg-block'>
            <ResultsearchForm />
          </div>

          <div className="container row mx-auto content">

            <main className="col-lg-9 col-12 my-2 ">
              {
                jobsArray.length > 0 && jobsArray.slice(0, 4).map((blog, i) => (
                  <>
                    <Link to={`/blog?id=${blog.id}`} className="" style={{}} >
                      <div className="blog-search-result" style={{ backgroundColor: "white", borderRadius: 20, marginLeft: 12, marginRight: 15, boxShadow: "#c0c1c4 5px 5px 5px", borderColor: "black", marginTop: 15, padding: 5 }}>
                        <h6 style={{ color: "blue" }} >{blog.title}</h6>
                        <p style={{ color: "black" }}>
                          {blog.introduction?.split(".")[0]}.
                        </p>
                      </div>
                    </Link>
                  </>
                ))
              }
              {/* <div style={{ marginLeft: 12 }}><AdvertisementSection /></div> */}
              <main className="">
                <div className="backgroundbody">
                  <div className='firstdata '>
                    <div className="gcse-searchresults-only" data-start="4" data-webSearchResultSetSize="8"></div>
                  </div>
                </div>
                {/* <div style={{ marginLeft: 12 }}><AdvertisementSection /></div> */}
              </main>
              {
                jobsArray.length > 0 && jobsArray.slice(5, 9).map((blog, i) => (
                  <>
                    <Link to={`/blog?id=${blog.id}`} className="" style={{}} >
                      <div className="blog-search-result" style={{ backgroundColor: "white", borderRadius: 20, marginLeft: 12, marginRight: 15, boxShadow: "#c0c1c4 5px 5px 5px", borderColor: "black", marginTop: 15, padding: 5 }}>
                        <h6 style={{ color: "blue" }} >{blog.title}</h6>
                        <p style={{ color: "black" }}>
                          {blog.introduction?.split(".")[0]}.
                        </p>
                      </div>
                    </Link>
                    {/* {
                      (i + 1) % 4 === 0 && <div style={{ marginLeft: 12 }}><AdvertisementSection /></div>
                    } */}
                  </>
                ))
              }
            </main>
            <div className="col-12 col-lg-3 my-2 ">
              {/* <div id='div-gpt-ad-1670930615804-0' style={{minWidth:"240px", minHeight: "250px"}}></div> */}
              <div className='py-3 px-2 '>
                {subscribeds === false ? (<>
                  <div className="card p-2 ">
                    <div className="text-primary">
                      <p><i className='bx bx-mail-send h4'></i> Create job alert</p>

                    </div>
                    <h4>Enter your Email below to receive your top new job matches directly in your inbox.</h4>
                    <input
                      type="email"
                      placeholder="Enter E-Mail "
                      onChange={
                        checkEmail}
                      className="my-2 border-0 px-2 email" />
                    <p className="text-danger ">{error}</p>
                    <div className="subscribe text-center mx-auto ">
                      <button className="btn text-light" onClick={subscribe}>Subscribe</button>
                    </div>
                  </div>
                </>) : (<>
                  <div className="card p-2 text-center giveheight d-flex justify-content-center align-items-center font-weight-bold">
                    <p className="font-weight-bold"> You subscribed successfully!</p>
                  </div>
                </>)}


                {/* <div className="card p-2 my-3  text-md-left col-lg-12 col-md-6 col-12" >
        <h4>Categories</h4>
      
  </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

    </>
  )
}

export default SearchResult