import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { getDataFacebook } from "../../api/Requests/authentication";
import { useSelector } from "react-redux";
import CustomLoader from "../../common/components/CustomLoader";
// import "./siteData.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "../../utility";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { areDates15DaysApart } from "./utility";
import Header from "../Header";
import TimeClock from "../TimeClock";

const columns = [
    {
        name: "Campaign",
        selector: (row) => row.channelName3 || row[0].name,
        minWidth:"350px",
        maxWidth:"500px",
        manualColumnResize:true,
        style: { border: "1px solid #ccc" }
    },
    {
        name: "Campaign Id",
        selector: (row) => row.channelName2 || row[0].id,
        style: { border: "1px solid #ccc" }
    },
    {
        name: "Spend",
        selector: (row) => Number(row["0"]?.insights?.data[0]?.spend || 0),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Earn",
        selector: (row) => Number(row.estimated_revenue),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Profit",
        selector: (row) => Number(row.estimated_revenue - (row["0"]?.insights?.data[0]?.spend || 0)),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Rpc",
        selector: (row) => Number(row.estimated_revenue / row.total_clicks > 0 ? row.estimated_revenue / row.total_clicks : 0),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Average Cpc",
        selector: (row) => Number(row.avgcpa)  || "NA",
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Cpa",
        selector: (row) => Number(row.Cpa)  || "NA",
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Conversion",
        selector: (row) => Number(row["0"]?.insights?.data[0]?.conversion) || "NA",
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Budget",
        selector: (row) => Number(row.Budget)  || "NA",
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Tcpa",
        selector: (row) => Number(row.Tcpa) || "NA",
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    {
        name: "Ctr",
        selector: (row) => Number(row["0"]?.insights?.data[0]?.ctr || 0),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center:true,
        reorder:true
    },
    
];

const CampaignDashboard = (props) => {
    const [data, setData] = useState({});
    const { apiToken, accessToken } = useSelector((state) => state.user);
    const history = useHistory();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(500);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [combinedData, setCombinedData] = useState({
        Profit:0,
        Earn:0,
        Spend:0,
        Budget:0
    })
    const [endDate, setEndDate] = useState(new Date());
    const [timeZone,setTimeZone] = useState("UTC")
    const [dateRange, setDateRange] = useState(7);


    const handlePerRowsChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handleStartDate = (date) => {
        setStartDate(date);
    };

    const getTableData = async () => {
        try {
            setIsLoading(true);
            if (endDate < startDate) {
                toast.error("EndDate cannot be before Start Date", {
                    autoClose: 2000,
                });
                setData({});
            } else if (areDates15DaysApart(startDate, endDate)) {
                toast.error("EndDate and Start Date cannot have more than 15 days gap", {
                    autoClose: 2000,
                });
                setData({});
            } else {
                const payload = {

                    start_date: getFormattedDate(startDate),
                    end_date: getFormattedDate(endDate),
                    page_size: perPage,
                    page_no: page,
                    apiToken,
                };
                const res = await getDataFacebook(payload);
                if (res.status === 200) {
                    // const formattedData = formatData(res.data.message.rows)
                    setData({rows: res.data });
                    createTotal(res.data)
                } else {
                    setData({});
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const createTotal = (data) =>{
        let Budget = 0
        let Spend = 0
        let Earn = 0
        let Profit = 0

        data.map((row)=>{
            Budget = Budget + Number(row.Budget || 0)
            Spend = Spend + Number(row["0"]?.insights?.data[0]?.spend || 0)
            Earn =  Earn + Number(row.estimated_revenue)
            Profit = Profit + Number(row.estimated_revenue - (row["0"]?.insights?.data[0]?.spend || 0))
        })

        setCombinedData({
            Earn,
            Spend,
            Profit,
            Budget
        })
        console.log({
            Earn,
            Profit,
            Spend,
            Budget
        })
        return
    }
    const formatData = (data) => {
        const uniqueIds = [...new Set(data.map(obj => obj.channelName2))];

        const groupedData = data
        

        // console.log(groupedData)

        return groupedData

    }

    useEffect(() => {
        getTableData();
    }, [perPage, page]);




    return (
        <div className="page-container" >
            {/* Header */}
            <Header />
            <div className="page-title">
                <h1>Facebook Campaign Data</h1>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignContent:"center", gap:5}}> 
                    <select defaultValue={timeZone} onChange={(e)=>setTimeZone(e.target.value)}>
                    <option value="UTC">UTC</option>
                    <option value="Asia/Kolkata">IND</option>
                    <option value="America/Los_Angeles">US</option>
                </select>
                <span style={{ fontSize:15}}><TimeClock timezone={timeZone}/></span>
                </div>
            </div>

            <div className="date-filter-container">
                <div className="date-picker-wrapper">
                    <label htmlFor="start-date">Start Date:</label>
                    <DatePicker
                        id="start-date"
                        selected={startDate}
                        onChange={handleStartDate}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select Start Date"
                        maxDate={new Date()}
                    />
                </div>

                <div className="date-picker-wrapper">
                    <label htmlFor="end-date">End Date:</label>
                    <DatePicker
                        id="end-date"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select End Date"
                        maxDate={new Date()}
                    />
                </div>
                <div onClick={() => { getTableData() }} className="date-picker-wrapper " style={{ backgroundColor: "green", color: "white", paddingLeft: 10, paddingRight: 10, cursor: "pointer", justifyContent: "center", alignItems: "center", marginTop: 15, marginBottom: 0, borderRadius: 10 }}>
                    Submit
                </div>
            </div>
            <div className=" w-full" style={{display:"flex", flexWrap:"wrap", flexDirection:"row", gap:15, marginBottom:20, paddingLeft:20, width:"100%"}}>
                <div className="border border-black py-1.5 px-5 rounded-lg">
                    <h3>Earn</h3>
                    <h4 className=" text-center">{combinedData.Earn}</h4>
                </div>
                <div className="border border-black py-1.5 px-5 rounded-lg">
                    <h3>Spend</h3>
                    <h4 className=" text-center">{combinedData.Spend}</h4>
                </div>
                <div className="border border-black py-1.5 px-5 rounded-lg">
                    <h3>Profit</h3>
                    <h4 className=" text-center">{combinedData.Profit}</h4>
                </div>
                <div className="border border-black py-1.5 px-5 rounded-lg">
                    <h3>Budget</h3>
                    <h4 className=" text-center">{combinedData.Budget}</h4>
                </div>
            </div>
            {/* Table Container */}
            <div className="table-site-container">
                <DataTable
                    columns={columns}
                    data={data.rows}
                    showGridlines
                    pagination
                    paginationServer
                    progressPending={isLoading}
                    paginationTotalRows={data?.pagination?.total_rows}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[500, 550, 600]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<CustomLoader />}
                    fixedHeader
                    customStyles={{
                        headCells: {
                          style: {border: "1px solid #ccc"
                          },
                        },          
                      }}
                    fixedHeaderScrollHeight="calc(100vh - 350px)"
                />
            </div>
        </div>
    );
};

export default CampaignDashboard;
