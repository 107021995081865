const CustomLoader = ({ size = "40px" }) => (
  <div style={{ textAlign: "center", padding: "20px" }}>
    <div
      className="spinner"
      style={{
        width: size,
        height: size,
        border: "4px solid #20444c", // Using your primary color
        borderRadius: "50%",
        borderTop: "4px solid transparent",
        animation: "spin 1s linear infinite",
      }}
    ></div>
    <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
  </div>
);

export default CustomLoader;
