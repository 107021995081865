export const areDates15DaysApart = (date1, date2) => {
  // Convert the dates to time (milliseconds since epoch)
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();

  // Calculate the difference in time
  const timeDifference = Math.abs(time2 - time1);

  // Convert the difference from milliseconds to days
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

  // Check if the difference is 15 days or more
  return dayDifference >= 15;
};
