import React, { useState, useEffect } from "react";
import "./loginPage.css";
import { INTIAL_ERROR, INTIAL_VALUE } from "./constant";
import { loginRequest } from "../../api/Requests/authentication";
import { login } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import CustomLoader from "../../common/components/CustomLoader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const LoginPage = () => {
  const [value, setValue] = useState(INTIAL_VALUE);
  const [errors, setErrors] = useState(INTIAL_ERROR);
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useSelector((state) => state.user);
  const history = useHistory();

  const dispatch = useDispatch();
  const loginReq = async () => {
    // const payload = {
    //   user_email: "contact@stocometry.com",
    //   password: "Bigloop@43233",
    // };
    const payload = {
      email: value.email,
      password: value.password,
    };
    try {
      setIsLoading(true);
      const res = await loginRequest(payload);
      if (res.status === 200) {
        dispatch(login(res.data.message));
        setValue(INTIAL_VALUE);
        history.push("/site-data");
        toast.success("Login Successful", {
          autoClose: 2000,
        });
      } else {
        toast.error(res?.data?.message, {
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (key) => (e) => {
    const value = e.target.value;
    setValue((pre) => {
      return { ...pre, [key]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(INTIAL_ERROR);
    if (!value.email) {
      setErrors({
        ...errors,
        email: {
          isError: true,
          message: "Please Fill this Field",
        },
      });
      return;
    }
    if (!value.password) {
      setErrors({
        ...errors,
        password: {
          isError: true,
          message: "Please Fill this Field",
        },
      });
      return;
    }
    loginReq();
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              id="email"
              value={value.email}
              onChange={handleChange("email")}
              placeholder="Enter your email"
              className={errors.email ? "input-error" : ""}
            />
            {errors.email.isError && <small className="error-text">{errors.email.message}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={value.password}
              onChange={handleChange("password")}
              placeholder="Enter your password"
              className={errors.password ? "input-error" : ""}
            />
            {errors.password.isError && <small className="error-text">{errors.password.message}</small>}
          </div>

          {isLoading ? (
            <div className="loader">
              <CustomLoader size="20px" />
            </div>
          ) : (
            <button type="submit" className="btn-submit">
              Log In
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
