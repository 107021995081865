import React, { useState } from "react";
import { Link } from "react-router-dom";
import { protectedRouteCheck } from "../utility";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const Header = () => {
  const [drawer, setDrawer] = useState(false)
  const location = useLocation();

  function openNav() {
    document.getElementById("mySidenav").style.width = "150px";
    document.getElementById("mySidenav").style.display = "block";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.display = "none";
  }
  return (
    <div>
      <header className="bg-gradient bg-gradient-to-br from-[#e0eef5] to-[#f1f1f1] ">
        <div className="container h-100">
          <nav class="navbar d-none d-lg-flex bg-red-100 h-100 navbar-expand-lg justify-content-between align-items-center">
            <a class="navbar-brand " href="/">
              <img src="/assets/img/logo.png" alt="img" className="img-fluid logoimage" />
            </a>
            {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
              </button> */}

            <ul class="navbar-nav  d-lg-flex  font-weight-bold text-dark">
              {/* <li class="nav-item ">
                <a class="nav-link text-dark" href="#">Jobs<span class="sr-only">(current)</span></a>
              </li> */}
              {protectedRouteCheck(location.pathname) ? (
                <>
                  <li class="nav-item" style={{ position: "relative", cursor: "pointer" }}>
                    <span class="nav-link text-dark" onClick={() => setDrawer(!drawer)}>Site Data  <span style={{ fontSize: 8 }}>▼</span></span>
                    {drawer && <div style={{ position: "absolute", backgroundColor: "white", border: "gray", borderWidth: 1 }}>
                      <a class="nav-link text-dark" href="/site-data">
                        Site Data
                      </a>
                      <a class="nav-link text-dark" href="/site-data-combined">
                        Combined Site Data
                      </a>
                      <a class="nav-link text-dark" href="/campaign-dashboard-facebook">
                        Facebook Campaign Data
                      </a>
                    </div>}
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-dark" href="/add-campaignId">
                      Add Campaign
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-dark" href="/campaign-data">
                      Campaign Data
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li class="nav-item">
                    <a class="nav-link text-dark" href="/aboutus">
                      About us
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-dark" href="/Contactus">
                      Contact us
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-dark" href="/blog?id=1">
                      Blog
                    </a>
                  </li>
                </>
              )
              }

              {/* <li class="nav-item">
                <a class="nav-link text-dark" href='/PrivacyPolicy'>Privacy Polic</a>
              </li> */}
            </ul>
          </nav>
          <nav class="navbar side-navbar-mobile   d-lg-none navbar-expand-lg justify-content-between align-items-center">
            <div class="container d-flex justify-content-between align-items-center">
              <a class="navbar-brand mr-0 mr-md-1" href="/">
                <img src="/assets/img/logo.png" alt="img" className="img-fluid logoimage" />
              </a>
              <button class="btn p-0 p-md-1" onClick={openNav}>
                <i class="bx bx-menu h4"></i>
              </button>
            </div>
            <ul id="mySidenav" class="sidenav list-unstyled">
              <li className="nav-item closebtn ">
                <a onClick={closeNav}>X</a>
              </li>
              {protectedRouteCheck(location.pathname) ?
                <>
                  <li class="nav-item">
                    <a class="nav-link" href="/site-data">
                      Site Data
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/site-data-combined">
                      Combined Site Data
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/campaign-dashboard-facebook">
                      Facebook Campaign Data
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/add-campaignId">
                      Add Campaign
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/campaign-data">
                      Campaign Data
                    </a>
                  </li>
                </> : <>
                  <li class="nav-item ">
                    <a class="nav-link " href="#">
                      Jobs<span class="sr-only">(current)</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link " href="/blog?id=1">
                      Blog
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link " href="/aboutus">
                      About us
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link " href="/Contactus">
                      Contact us
                    </a>
                  </li>
                </>}

            </ul>
          </nav>
        </div>
        {/* <div id="header p-3">
          <div className="container d-flex justify-content-between align-items-center">
            <div className="py-3">
              <div id="logo">
              <a href="/">
                <img src='/assets/img/logo.png' alt="img" className='img-fluid logoimage' />
                
                </a>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="py-3">
              <ul className="list-unstyled border-0">
                <li className="border-0 text-light">
                <Link
                      to="/Contactus"
                      className="text-dark text-decoration-none"
                    >
                      <span>Contact </span>
                    </Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </header>
      <div className="clearfix"></div>
    </div>
  );
};
export default Header;
