import axios from "axios";
import { API_URL } from "../requestHandler";

export const redirectUrl = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/v1/redirectedUrl`, data);
    return response;
  } catch (err) {
    console.log(err.response.data, "err1");
    return err.response;
  }
};
