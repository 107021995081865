import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { getData } from "../../api/Requests/authentication";
import { useSelector } from "react-redux";
import CustomLoader from "../../common/components/CustomLoader";
import "./campaignData.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "../../utility";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Header";
import DropdownSelect from "../../common/components/DropdownSelect";
import { getAllCampaignId, getAllSmid, getCustomCampaignData } from "../../api/Requests/campaign";
import TimeClock from "../TimeClock";

const columns = [
  {
    name: "SMID",
    selector: (row) => row.smid,
  },
  {
    name: "Campaign Id",
    selector: (row) => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{row.campaign_id}</div>,
  },
  {
    name: "Impressions",
    selector: (row) => row.impressions,
  },
  {
    name: "Estimated Revenue",
    selector: (row) => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{row.estimated_revenue}</div>,
  },
];

const customStyles = {
  rows: {
    style: {
      fontSize: "12px",
      minHeight: "20px",
      color: "gray",
    },
  },
};

const CampagnTable = (props) => {
  // Columns for the table

  const [data, setData] = useState({});
  const [smidOptions, setSmidOptions] = useState([]);
  const [campaignIdOptions, setCampaignIdOptions] = useState([]);
  const { apiToken, accessToken } = useSelector((state) => state.user);
  const history = useHistory();
  const [smid, setSmid] = useState();
  const [campaignId, setcampaignId] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [timeZone,setTimeZone] = useState("UTC")

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const getTableData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        campaign_id: campaignId?.campaign_id === "None" || !campaignId?.campaign_id ? null : campaignId?.campaign_id,
        smid: smid?.smid === "None" || !smid?.smid ? null : smid?.smid,
        page,
        pageSize: perPage,
      };
      const res = await getCustomCampaignData(payload);
      if (res.status === 200) {
        setData(res.data.message);
      } else {
        setData({});
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getFilterData = async () => {
    try {
      const res1 = await getAllSmid();
      const res2 = await getAllCampaignId();
      if (res1.status === 200) {
        setSmidOptions(res1?.data?.message || []);
      }
      if (res2.status === 200) {
        setCampaignIdOptions(res2?.data?.message || []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    getTableData();
  }, [perPage, page, smid, campaignId]);

  const ExpandedComponent = ({ data }) => {
    console.log({ data });
    return (
      <div style={{ marginLeft: "45px" }}>
        <DataTable columns={columns} noHeader noTableHead customStyles={customStyles} data={[data]} />
      </div>
    );
  };
  return (
    <div className="campaign-page-container">
      {/* Header */}
      <Header />
      {/* <h1 className="campaign-page-title">Campaign Data</h1> */}
      <div className="page-title">
                <h1> Campaign Data</h1>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignContent:"center", gap:5}}> 
                    <select defaultValue={timeZone} onChange={(e)=>setTimeZone(e.target.value)}>
                    <option value="UTC">UTC</option>
                    <option value="Asia/Kolkata">IND</option>
                    <option value="America/Los_Angeles">US</option>
                </select>
                <span style={{ fontSize:15}}><TimeClock timezone={timeZone}/></span>
                </div>
            </div>

      <div className="filter-container">
        <DropdownSelect options={smidOptions} placeholder="Choose a Smid" onSelect={setSmid} header="Smid" objKey="smid" />
        <DropdownSelect
          options={campaignIdOptions}
          placeholder="Choose a Campaign Id"
          onSelect={setcampaignId}
          header="Campaign Id"
          objKey="campaign_id"
        />
      </div>

      {/* Table Container */}
      <div className="table-campaign-container">
        <DataTable
          columns={columns}
          data={data?.list}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandableRowDisabled={(row) => row.backupTable?.length <= 0}
          pagination
          progressPending={isLoading}
          paginationTotalRows={data?.total_rows}
          paginationPerPage={perPage}
          paginationServer
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressComponent={<CustomLoader />}
          fixedHeader
          fixedHeaderScrollHeight="calc(100vh - 350px)"
        />
      </div>
    </div>
  );
};

export default CampagnTable;
